.header_main_container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 39rem;
  z-index: 100;
}
.header_container {
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  width: 30%;
  padding-right: 20px;
  position: fixed;
  background-color: rgb(29, 8, 45);
}

.header_container > ul {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  gap: 3rem;
}

.header_container > ul > li {
  list-style: none;
  text-decoration: none;
}

.image {
  height: 1.5rem;
  width: 1.5rem;
  filter: invert(1);
  padding: 10px;
}

.image:hover {
  cursor: pointer;
  background-color: rgb(73, 240, 123);

  border-radius: 10px;
}

@media screen and (max-width: 786px) {
  .header_main_container {
    width: 2rem;
    height: 2rem;
    padding-left: 20rem;
    position: sticky;
  }
  .image {
    position: relative;
    right: 20px;
    height: 1rem;
    width: 1rem;
    padding: 0rem 1rem;
  }
  .header_container {
    width: fit-content;
    padding: 0px;
    height: 3rem;

    position: sticky;
  }
  .header_container > ul {
    display: flex;
    gap: 0;
  }
  .header_main_container {
    display: none;
  }
}
