.work_main_container {
  display: flex;
  padding-top: 3rem;
}

.left_work {
  flex: 1 1;
}

.right_work {
  flex: 1 1;

  gap: 1rem;
}

.data_container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 1rem 0 1rem 0;
}
.data_container > :nth-child(1) {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 2rem 0rem;
}
.data_container > :nth-child(2) {
  font-size: small;
  font-weight: 500;
  padding-right: 4rem;
}
.main_container > hr {
  width: 30rem;
  opacity: 0.2;
  position: absolute;

  padding-left: 15rem;
}
.main_container {
  display: flex;
  position: relative;
}
.img_container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem;
}
.img_container > span {
  width: 10rem;
  font-weight: bold;
  transition: all 0.2s;
}
.img_container > span:hover {
  font-size: 15px;
  cursor: pointer;
}
.img_container > img {
  width: 2rem;
  height: 2rem;
  filter: invert(1);
  transition: all 1s;
}
.img_container > img:hover {
  filter: invert(1);
  cursor: pointer;
  transform: rotateZ(312deg);
}
.left_container {
  padding: 5rem 0 0 10rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.left_container > span {
  font-size: 5rem;
  text-transform: capitalize;
}
.btn_container > img {
  width: 30rem;
  height: 30rem;
  opacity: 0.6;
}
.blur1 {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  bottom: 0px;
}
@media screen and (max-width: 786px) {
  .left_container {
    padding: 0rem 0rem 5rem 0rem;
  }
  .left_container > :nth-child(1) {
    font-size: 2.6rem;
    padding-left: 1rem;
    position: relative;
    left: 8rem;
    bottom: 3rem;
  }
  .right_work {
    position: relative;
    top: 2rem;
    right: 6.5rem;
  }
  .btn_container > img {
    display: none;
  }
  .main_container {
    font-size: 10px;
  }
  .main_container > hr {
    position: relative;
    right: 1rem;
  }
  .data_container {
    width: 22rem;
  }
  .data_container > span {
    font-size: 2px;
  }
  .img_container > span {
    font-size: 0.7rem;
    position: relative;
    right: 2rem;
  }
  .img_container > img {
    position: relative;
    top: 1rem;
  }
  .btn_container > :nth-child(1) {
    display: none;
  }
}
