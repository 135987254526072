.profile_container {
  display: flex;
  padding-top: 3rem;
}

.left_p {
  flex: 1 1;
}

.right_p {
  flex: 1 1;
}

/* profile content  */

.profile_content {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  padding: 9rem 13rem 0 2rem;
}
.profile_content > :nth-child(1) {
  font-size: 2.7rem;
  text-transform: uppercase;
  color: rgb(150, 47, 150);
  font-weight: 900;
}
.profile_content > :nth-child(2) {
  text-transform: uppercase;

  font-weight: 900;
}

/* numbers */
.numbers {
  display: flex;
  gap: 3rem;
  padding: 2rem 0 0 2rem;
}

.number {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  text-transform: capitalize;
  width: 6rem;
}
.number > :nth-child(1) {
  font-size: 3rem;
}
.number > :nth-child(2) {
  font-weight: 900;
}
/* buttons */
.btn1 {
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  margin-top: 0.7rem;

  font-weight: 500;
  transition: all 1s;
}
.btn1:hover {
  filter: brightness(1);
}

.btn_container {
  padding: 2rem 0 0 2rem;
  display: flex;
  gap: 2rem;
}

/* left side */

.img {
  padding: 3.5rem 0 0 10rem;
  width: 90%;
  height: 90%;
}

.p_blur {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(250px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  left: 10rem;
}
.p_blur1 {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(450px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  right: 10rem;
}
@media screen and (max-width: 786px) {
  .img {
    width: 1px;
    height: 2px;
    padding: 0px;
    display: none;
  }
  .profile_container {
    padding-bottom: 3rem;
    position: relative;
    right: 1rem;
    bottom: 5rem;
  }
  .left_p > :nth-child(1) {
    width: 10rem;
    padding: 0px;
  }
  .right_p {
    padding: 0px;
  }
  .profile_content > :nth-child(1) {
    font-size: 5rem;
  }
  .profile_content > :nth-child(2) {
    font-size: 2.5rem;
    padding-bottom: 2rem;
  }
  .p_blur {
    filter: blur(350px);
  }
}
