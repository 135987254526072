.project_container {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.left_p {
  flex: 1 1;
}

.right_p {
  flex: 1 1;
}

.left_container {
  display: flex;
  flex-direction: column;
  padding: 6rem 0 0 20rem;
}
.left_container > :nth-child(1),
.left_container > :nth-child(2) {
  font-size: 2rem;
  font-weight: bold;
}
.left_container > :nth-child(2) {
  padding-bottom: 3rem;
}
.left_container > :nth-child(3) {
  padding-bottom: 3rem;
}
.btn1 {
  margin-bottom: 2rem;
}
.box {
  border: 1px solid white;
  border-radius: 10px;
  transition: all 1s;
  overflow: hidden;
  width: 25rem;
  height: 20rem;
  position: relative;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  background: transparent;

  backdrop-filter: blur(5px);
  border-radius: 10px;
  z-index: 10;
}
.overlay > span {
  display: flex;
  flex-direction: column;
  padding: 5rem 2rem;
  position: relative;
  left: 6rem;
}
.overlay > span:nth-of-type(1) {
  font-size: 2rem;
}
.box > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.box > img:hover {
  overflow: hidden;
  transform: scale(2);
}

.right_p {
  padding-top: 2.7rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.blur1 {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(250px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  right: 0px;
}
.blur2 {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(280px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  margin-top: 20rem;
  left: 10px;
}
@media screen and (max-width: 786px) {
  .left_p {
    padding-top: 10rem;
    padding-left: 2rem;
  }
  .left_container {
    padding: 0px;
    font-size: 10px;
    width: 20rem;
  }
  .right_p {
    position: relative;
    top: 39rem;
    right: 21rem;
  }
  .box {
    width: 23rem;
    height: 15rem;
  }
}
