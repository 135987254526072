.main_container {
  display: flex;
  padding-top: 10rem;
  padding-bottom: 2rem;
  padding-bottom: 7rem;
}

.left_form {
  flex: 1 1;
}

.right_form {
  flex: 1 1;
}

.main_form {
  padding-top: 10rem;
  padding: 2rem 2rem 2rem 1rem;
  border: 1px solid white;
  border-radius: 20px;
  width: 70%;
  height: fit-content;
}

.main_form > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.main_form > form > div > input {
  border: 1px solid transparent;
  background: transparent;
  border-bottom: 0.5px solid;
  color: white;
  padding-bottom: 2rem;
  overflow: hidden;
  text-align: start;
  height: 100%;
  width: 100%;
}
.main_form > form > div > input:focus {
  outline: none;
  border-bottom: 1px solid rgb(131, 18, 179);
}

.main_form > form > div > input:nth-of-type(3) {
  padding-top: 5rem;
}

.form_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  padding-left: 13rem;
}
.form_content > :nth-child(1) {
  color: rgb(131, 18, 179);
  font-size: 20px;
  text-transform: capitalize;
}
.form_content > :nth-child(2),
.form_content > :nth-child(3) {
  font-size: 4rem;
}

.error_msg {
  color: white;
  font-size: 10px;
}

.blur {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(350px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  margin-top: 10rem;
  left: 0px;
}
@media screen and (max-width: 786px) {
  .form_content > span {
    font-size: 20px;
  }
  .form_content {
    position: relative;
    right: 10rem;
    top: 25rem;
  }
  .main_form {
    position: relative;
    right: 27rem;
    top: 45rem;
    width: 17rem;
  }
}
