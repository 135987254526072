:root {
  background-color: rgb(24, 2, 53);
}
::-webkit-scrollbar {
  display: none;
}
.App {
  font-family: "Josefin Sans", sans-serif;
  color: white;
}
.rotateimg {
  animation: App-logo-spin 1 2s linear;
}
@keyframes App-logo-spin {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.grad {
  background: -webkit-linear-gradient(
    rgb(241, 142, 226),
    rgb(164, 65, 179),
    rgb(82, 231, 231)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.grad > button {
  background: -webkit-linear-gradient(
    rgb(241, 142, 226),
    rgb(164, 65, 179),
    rgb(82, 231, 231)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.grad > button:hover {
  background-image: linear-gradient(
    rgb(82, 231, 231),
    rgb(164, 65, 179),
    (241, 142, 226)
  );
  cursor: pointer;
}

.stroke_text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn > button {
  font-size: x-small;
  padding: 1rem;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 900;
  background-image: linear-gradient(
    to right,
    rgb(233, 79, 210),
    rgb(214, 87, 234),
    rgb(82, 231, 231)
  );
  border: none;
  color: white;
  transition: all 0.3s;
}
.btn > button:hover {
  cursor: pointer;
  background-image: none;
  background-color: rgb(29, 8, 45);
}

.error-msg {
  color: white;
}

.blur {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  left: 0px;
}
