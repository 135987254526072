.logo_container {
  display: flex;
}
.logo {
  flex: 1 1;
  margin-top: 2rem;
}
.heading {
  padding: 0 0 0 8rem;
  display: flex;
  flex-direction: column;
}
.heading > span:nth-of-type(1) {
  background: -webkit-linear-gradient(
    rgb(241, 142, 226) 30%,
    rgb(214, 87, 234) 30%,
    rgb(82, 231, 231)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.heading > span {
  font-size: 2.5rem;
}
.logo_r {
  display: flex;
  flex: 1 1;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 0 0 45rem;
}

/* content */

.content_container {
  display: flex;
}

.content_l {
  flex: 1 1;
}
.content_r {
  flex: 1 1;
}
.content_heading {
  display: flex;
  flex-direction: column;
  padding: 8rem 0 0 13rem;
}
.content_heading > span:nth-of-type(1) {
  font-size: 4.2rem;
  font-weight: bold;
}
.content_heading > span:nth-of-type(2) {
  font-size: 2.3rem;
  font-weight: bold;
}

.heading_sub {
  display: flex;
  gap: 1rem;
  font-size: 2rem;
}
.btn_container {
  display: flex;
  padding: 2rem 0 0 13rem;
}
.btn1 {
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  margin-top: 0.7rem;
  margin-left: 2rem;
  font-weight: 500;
  transition: all 1s;
}
.btn1:hover {
  cursor: pointer;
  border: 1px solid white;
  padding: 0.1rem 1rem;
}
.socials {
  display: flex;
}
.img {
  width: 2rem;
  height: 2rem;
  padding: 2rem 0rem 0 14rem;

  transition: all 1s;
}
.img:hover {
  cursor: pointer;
  filter: brightness(10);
  filter: drop-shadow(2);
}

.subtitle {
  padding: 1rem 8rem 0 13rem;
}
.subtitle > span {
  text-align: start;
}

/* right side */

.ai > img {
  width: 88%;
  height: 88%;
  filter: invert(1);
}

.home_blur {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
  width: 22rem;
  height: 30rem;
  left: 0px;
}
.home_blur1 {
  background: rgb(230, 49, 230);
  position: absolute;
  border-radius: 50%;
  filter: blur(350px);
  z-index: -9;
  width: 22rem;
  height: 22rem;
  right: 0px;
}

@media screen and (max-width: 786px) {
  .Home_main_container {
    padding-bottom: 18rem;
  }
  .heading {
    height: 2rem;
    width: fit-content;
    padding: 1rem 2rem;
  }
  .logo {
    margin: 0;
  }
  .heading > span {
    font-size: 30px;
    padding-bottom: 0rem;
  }
  .logo_r {
    position: absolute;
    top: 0px;
    right: 2rem;
    padding: 0px;
    width: fit-content;
    height: 1rem;
  }
  .content_heading {
    margin: 0px;
    padding: 5rem 0 3rem 1rem;
  }
  .content_heading > span:nth-of-type(1) {
    font-size: 55px;
    padding-bottom: 2rem;
  }

  .heading_sub {
    padding: 1rem 0rem;
    font-size: 35px;
  }
  .subtitle {
    padding: 1rem 1rem;
    font-size: 20px;
    width: 20rem;
  }
  .btn_container {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
  }
  .btn_container > :nth-child(1) {
    padding-left: 1rem;
    padding-top: 1rem;
  }
  .btn_container > :nth-child(2) {
    padding: 0px;
  }
  .btn1 {
    margin: 0px;
    padding-top: 0.7rem;
    padding-left: 1.5rem;
    font-size: 1rem;
  }
  .socials {
    padding: 0px;
    margin: 0px;
    position: relative;
    right: 13rem;
    top: 10rem;
  }
  .img {
    width: 10px;
    height: 10px;
  }
  .ai {
    position: relative;
    top: 30rem;
    right: 12rem;
  }
  .ai > img {
    width: 15rem;
  }
  .heading_sub > :nth-child(3) {
    font-size: 2rem;
  }
  .heading_sub > :nth-child(2) {
    font-size: 2rem;
  }
  .home_blur {
    width: 10rem;
    height: 20rem;
  }
}
